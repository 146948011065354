<template>
  <pt-bill-back class="pt-bill-back__withData"></pt-bill-back>
</template>

<script>
import PtBillBack from './index'
export default {
  name: 'pt-bill-back__withData',
  components: {
    'pt-bill-back': PtBillBack
  }
}
</script>
