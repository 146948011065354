<template>
  <div :class="$style['pt-bill-back']">
    <validate-form tag="div" ref="form">
      
      <pt-form-item
        class="form-item"
        tag="div"
        name="上传委托单扫描件"
        ref="username"
      >
        <div :class="[$style['form-item-box']]">
          <span style="margin-top: 10px;">上传委托单扫描件：</span>
          <cube-upload
          ref="upload"
          v-model="formValue.attachments"
          :action="action"
          />
        </div>
      </pt-form-item>
      <pt-form-item
        class="form-item"
        tag="div"
        name="委托单编号"
        rules="required"
      >
        <div :class="[$style['form-item-box'],$style['required']]">
          <span>委托单编号：</span>
          <wt-input v-model="formValue.billCode" placeholder="请填写委托单编号">
          </wt-input>
        </div>
      </pt-form-item>
    </validate-form>
    <div :class="$style['btn-box']">
      <wt-button :class="$style['submit-btn']" @click="submit" primary>确认已回传</wt-button>
    </div>
  </div>
</template>

<script>
 import { formatDate } from '@/utils/formatUtils/date.js'
 import {nextTrust} from '../status'
// 组件class前缀
const prefixCls = 'pt-bill-back'

export default {
  name: 'pt-bill-back',
  computed: {
    classes () {
      return `${prefixCls}`
    }
  },
  data () {
    return {
      action:{
        withCredentials:true,
        target:'http://backend-console.lhsit.wingedcare.com/lab/api/upload',
        data:{
          resourceName: 'bill',
          objectId: this.$route.params.id || 0
        }
      },
      privateResourcesUrl: this.$privateResourcesUrl,
      formValue: {
        billCode:`${this.getDateStr()}-YFC-`
      }
    }
  },
  methods:{
    getDateStr(){
      let dateStr = formatDate(new Date(),'date').split('-').join('')
      return dateStr.substring(2)
    },
    submit () {
      this.$refs.form.validate("formValue").then((valid) => {
        console.log('---valid',valid)
        if(!valid){
          return
        }
        let data = {
          action: 'BILL_BACK',
          billId:this.$route.params.id,
          billStatusDetail:{
            action: 'BILL_BACK',
            billCode: this.formValue.billCode,
            attachmentFileInformationList: this.formValue.attachments?.map(item => {
            return {
              type: 'BILL_PDF_BACK',
              name: item.name,
              ossUrl: item.response
            }
          })
          }
        }
        this.$loading()
        nextTrust.call(this,data).then(response => {
          this.$router.go(-1)
        }).catch(err => {
          console.log('--err',err)
          this.$toast.error(err.message || err)
        }).finally(()=>{
          this.$loading.hide()
        })
      });
      
    
    },
  }
}
</script>

<style module lang="less">
/* 已启用css module， template中请使用$style.className添加class */
/* 务必遵循css BEM原则 */
@import "~less/var.less";
.pt-bill-back{
  > div:first-child{
    background-color: #fff;
    padding:@page-padding 25px;
    >div{
      &:last-child{
        .form-item-box{
          border-bottom: none;
        }
      }
    }
  }
  .btn-box{
    padding: 20px @page-padding;
    :global{
      .cube-btn{
        width: 100%;
      }
    }
  }
}
.form-item-box{
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid @page-background-color;
  &.required{
    > span{
      position: relative;
      &::before{
        content: '*';
        position: absolute;
        color:red;
        left: -10px;
        top: 0;
        width: auto;
      }
    }
  }
  > span{
    white-space: nowrap;
    width: 150px;
    text-align: left;
    margin-top: 10px;
  }
  > p{
    padding: 10px;
  }
  :global{
    .wt-input,
    .cube-select{
      flex: 1;
      text-align: left;
      &::after{
        border: none;
      }
    }
  }
}
</style>
